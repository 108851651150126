export enum Char_Class {
    cleric = 'cleric',
    dwarf = 'dwarf',
    elf = 'elf',
    fighter = 'fighter',
    halfling = 'halfling',
    magic_user = 'Magic-User',
    thief = 'thief',
    normal_human = 'normal human',
}
