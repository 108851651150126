import Home from './components/Home'

const App = () => {
    return (
        <div className="main">
            <Home />
        </div>
    )
}

export default App
